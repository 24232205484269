<template>
  <div class="wap-lang">
    <headerBar :title="$t('yu-yan-she-zhi')" @back="back"></headerBar>

    <div class="lang-list">
      <div
        class="list flex-center-between"
        v-for="(item, i) in langList"
        :key="i"
        @click="changeLang(item)"
      >
        <div class="flex-center">
          <img :src="item.icon" alt="" />
          {{ item.name }}
        </div>
        <div v-show="item.key == lang">
          <Icon name="success" size="18" color="#1552f0"></Icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import headerBar from '@/components/header'
export default {
  components: {
    Button,
    headerBar,
    Icon
  },
  data() {
    return {}
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    console.log(111, this.lang)
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeLang(data) {
      this.$store.state.lang = data.key
      this.$store.state.langName = data.name
      localStorage.setItem('locale', data.key)
      localStorage.setItem('localeName', data.name)
      this.i18n.locale = data.key
      setTimeout(() => {
        this.back()
      }, 500)
    }
  }
}
</script>